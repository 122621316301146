(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon/assets/javascripts/coupon.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon/assets/javascripts/coupon.js');
"use strict";

const {
  useContext,
  useState
} = React;
const {
  EngineProviderContext
} = svs.components.tipsen.engine;
const {
  DrawContext
} = svs.components.tipsen.drawProvider;
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const {
  useIsCurrentDrawPlayable,
  getOutcomeLabels
} = svs.components.tipsen.hooks;
const {
  CouponRow,
  CouponSingleRows
} = svs.components.tipsen.coupon;
const {
  useObserveSettings
} = svs.components.tipsen.coupon.hooks;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  selectNumberOfBoards
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  selectShowMatchAnalysesInCoupon
} = svs.components.tipsen.selectors;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_AM
} = svs.components.lbUtils.useMediaQuery;
const {
  useSelector
} = ReactRedux;
const Paper = svs.ui.ReactPaper;
const Button = svs.ui.ReactButton;
const {
  ScrollableButtonGroup
} = svs.components.tipsen.scrollableButtonGroup;
const containerSelector = '.coupon-singels-mobile-boards';
const {
  FetchDrawAnalysesByHook
} = svs.components.tipsen.hooks;
const labels1X2 = [{
  text: '1',
  sign: '1'
}, {
  text: 'X',
  ariaLabel: 'Kryss',
  sign: 'X'
}, {
  text: '2',
  sign: '2'
}];
const Coupon = () => {
  var _currentEngine$outcom;
  const {
    currentEngine
  } = useContext(EngineProviderContext);
  const couponId = useCouponId();
  const draw = useCurrentDraw();
  const boardSize = useSelector(state => selectNumberOfBoards(state, couponId));
  const isDrawPlayable = useIsCurrentDrawPlayable();
  const {
    eventNumber
  } = useContext(DrawContext);
  const [selectedBoard, setSelectedBoard] = useState(0);
  const isSingelRows = boardSize > 1;
  const mappedBoards = [];
  for (let i = 0; i < boardSize; i++) {
    mappedBoards.push(i);
  }
  const showMatchAnalysesInCoupon = useSelector(selectShowMatchAnalysesInCoupon);
  const shouldFetchMatchAnalyses = !showMatchAnalysesInCoupon.hideAll;
  const productId = draw.productId;
  const sportType = draw.sportType;
  const outcomeHeatmap = (_currentEngine$outcom = currentEngine.outcomes.outcomeHeatmap) === null || _currentEngine$outcom === void 0 ? void 0 : _currentEngine$outcom[sportType];
  let outcomeLabels;
  if (outcomeHeatmap) {
    const minValue = outcomeHeatmap.minValue;
    const maxValue = outcomeHeatmap.maxValue;
    outcomeLabels = getOutcomeLabels(minValue, maxValue, productId);
  } else {
    outcomeLabels = labels1X2;
  }
  useObserveSettings();
  const renderCoupon = () => isSingelRows ? React.createElement(CouponSingleRows, {
    boardSize: boardSize,
    couponId: couponId,
    events: draw.drawEvents,
    hasDisabledBets: !isDrawPlayable,
    outcomeLabels: outcomeLabels,
    selectedBoard: selectedBoard
  }) : React.createElement("div", {
    className: "coupon-rows",
    "data-testid": "standard-coupon"
  }, draw.drawEvents.map((drawEvent, index) => React.createElement(CouponRow, {
    board: 0,
    couponId: couponId,
    event: index,
    hasDisabledBets: !isDrawPlayable,
    isSelected: drawEvent.eventNumber === eventNumber,
    key: drawEvent.eventNumber,
    outcomeLabels: outcomeLabels
  })));
  return React.createElement(React.Fragment, null, useMediaQuery(breakpoints.down(BREAKPOINT_AM)) && isSingelRows && React.createElement("div", {
    className: "coupon-singels-mobile-boards",
    role: "radiogroup"
  }, React.createElement(ScrollableButtonGroup, {
    containerSelector: containerSelector
  }, mappedBoards.map(board => React.createElement(Button, {
    "aria-checked": selectedBoard === mappedBoards.indexOf(board),
    className: "coupon-singels-mobile-boards-button"
    ,
    key: board,
    onClick: () => setSelectedBoard(mappedBoards.indexOf(board))
  }, React.createElement("span", null, selectedBoard === mappedBoards.indexOf(board) ? "Rad ".concat(board + 1) : board + 1))))), React.createElement(Paper, {
    "aria-label": "Kupong",
    branding: "sport",
    className: "coupon",
    Component: "section"
  }, shouldFetchMatchAnalyses && React.createElement(FetchDrawAnalysesByHook, {
    drawNumber: draw.drawNumber,
    productId: draw.productId
  }), renderCoupon()));
};
setGlobal('svs.components.tipsen.coupon.Coupon', Coupon);

 })(window);